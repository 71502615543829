import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import {
  LivPortfolioErrorResponse,
  LivPortfolioResponseProtocol,
  LivPortfolioSuccessResponse,
  Meta
} from '../models/liv-portfolio-response-protocol.model';
import { ToastService } from '../services/toast.service';
import { shouldRetry } from 'src/app/shared/rxjs/custom-operators';
import { Router } from '@angular/router';
import { ErrorType } from 'src/app/auth/auth.component';
import { StorageService } from '../services/storage.service';
import { PortfolioStorage } from '../enums/portfolio-storage.enum';

export const urisToIgnoreOn401ErrorRequest = ['kibana', 'login'];

export function requestIgnore401RequestError(
  request: HttpErrorResponse
): boolean {
  return !new RegExp(urisToIgnoreOn401ErrorRequest.join('|')).test(request.url);
}

@Injectable()
export class ResponseProtocolInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private storageService: StorageService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<
    HttpEvent<LivPortfolioSuccessResponse | LivPortfolioErrorResponse>
  > {
    return next.handle(req).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          return event.clone({ body: this.handleResponse(event) });
        }
        return event;
      }),
      retry(
        shouldRetry({
          maxRetryAttempts: 2,
          scalingDuration: 3000,
          excludedStatusCodes: [404, 403, 401, 400]
        })
      ),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  handleResponse(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: HttpResponse<any>
  ): LivPortfolioSuccessResponse | LivPortfolioErrorResponse {
    const response: LivPortfolioResponseProtocol = event.body;

    if (
      event.url?.includes('assets') ||
      event.url?.includes('google') ||
      event.body instanceof Blob
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return event.body;
    }

    if (response.data) {
      return {
        data: response.data,
        meta: response.meta || ({} as Meta)
      };
    } else {
      if (response.error) {
        if (response.error.status === 401) {
          throw new HttpErrorResponse({
            status: response.error.status,
            error: response.error,
            url: event.url
          });
        }
      }
      throw new HttpErrorResponse({
        status: event.status,
        error: event.body,
        url: event.url
      });
    }
  }

  handleError(response: HttpErrorResponse): Observable<never> {
    if (response.status === 401 && requestIgnore401RequestError(response)) {
      this.removeAccessTokens();
      this.router.navigate(['/auth'], {
        queryParams: {
          error: ErrorType.AccessDenied
        }
      });
      return EMPTY;
    }

    if (response.status === 500) {
      this.toastService.error(
        'Ocorreu um erro inesperado! Tente novamente mais tarde!'
      );
    }

    const _response = response.error as LivPortfolioErrorResponse;

    return throwError(() => {
      return {
        status: response.status,
        error: {
          message: _response?.error?.message || 'Ocorreu um erro inesperado!',
          code: _response?.error?.code,
          details: {
            ..._response?.error?.details,
            url: response.url
          }
        }
      } as LivPortfolioErrorResponse;
    });
  }

  private removeAccessTokens() {
    this.storageService.delete(PortfolioStorage.AccessToken);
    this.storageService.delete(PortfolioStorage.PortalToken);
  }
}
