import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const whiteList = ['/assets/', '/importData', '/upload'];

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const whiteListed = new RegExp(whiteList.join('|')).test(req.url);
    if (whiteListed || req.headers.has('x-skip-interceptor')) {
      const headers = req.headers.delete('x-skip-interceptor');
      const request = req.clone({ headers });

      return next.handle(request);
    }

    const request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    return next.handle(request);
  }
}
