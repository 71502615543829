import { Injectable } from '@angular/core';
import { ApiLivGatewayService } from './api-liv-gateway.service';
import {
  KibanaCustomTeacher,
  KibanaPortfolioExternalLink,
  KibanaPortfolioModel,
  KibanaPortfolioRecordModel,
  KibanaPortfolioRegisterPagesEdit
} from '../../store/kibana/kibana.models';
import { Observable } from 'rxjs';
import {
  LivPortfolioResponseProtocol,
  LivPortfolioSuccessResponse
} from '../../models/liv-portfolio-response-protocol.model';
import { ApiGatewayService } from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class KibanaService {
  constructor(
    private apiLivGatewayService: ApiLivGatewayService,
    private apiGatewayService: ApiGatewayService
  ) {}

  saveKibanaGeneral(
    data: KibanaPortfolioModel
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.apiLivGatewayService.post(`/Kibana/Portifolio`, data);
  }

  saveKibanaRegister(
    data: KibanaPortfolioRecordModel
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.apiLivGatewayService.post(`/Kibana/Portifolio/Record`, data);
  }

  saveKibanaRegisterPagesEdit(
    data: KibanaPortfolioRegisterPagesEdit
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.apiLivGatewayService.post(`/Kibana/Portifolio/PagesEdit`, data);
  }

  saveKibanaExternalLink(
    data: KibanaPortfolioExternalLink
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.apiLivGatewayService.post(
      `/Kibana/Portifolio/ExternalLink`,
      data
    );
  }

  saveKibanaCustomTeacher(
    data: KibanaCustomTeacher
  ): Observable<LivPortfolioResponseProtocol<void>> {
    return this.apiGatewayService.post('/custom/kibana/teacher', data);
  }
}
