import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { LoadingOverlayService } from 'src/app/shared/components/loading-overlay/loading-overlay.service';
import { PortfolioStorage } from '../../enums/portfolio-storage.enum';
import gcpEnv from '../../../../environments/environment.gcp.json';

import { ErrorType } from 'src/app/auth/auth.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StrapiUserSessionModel } from '../../models/strapi-user-session.model';
import {
  Data,
  LivPortfolioErrorResponse,
  Model
} from '../../models/liv-portfolio-response-protocol.model';
import { StorageService } from '../../services/storage.service';

interface Tokens {
  accessToken: string;
  portalToken: string;
}

interface Tokens {
  accessToken: string;
  portalToken: string;
}

const LOCAL_USER_MAIL = gcpEnv['mail'] || ('' as const);
const LOCAL_USER_PASSWORD = gcpEnv['password'] || ('' as const);

@Injectable({
  providedIn: 'root'
})
export class BeforeLoadGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private loadingOverlayService: LoadingOverlayService,
    private httpClient: HttpClient
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.loadingOverlayService.open();

    const tokens =
      this.getTokensFromRoute(route) || this.getTokensFromStorage();

    if (!tokens) {
      return this.authenticateLocalUserOrRedirect();
    }

    return this.canAccess(tokens);
  }

  private authenticateLocalUserOrRedirect() {
    if (this.validateLocalOrigin(window.location.href)) {
      return this.authenticateLocalUser();
    } else {
      return of(
        this.router.createUrlTree(['/auth'], {
          queryParams: { error: ErrorType.AccessDenied }
        })
      );
    }
  }

  private getTokens(route: ActivatedRouteSnapshot) {
    let accessToken = route.queryParamMap.get('liv-token-pto');
    let portalToken = route.queryParamMap.get('liv-token-ptl');

    // Validar se os tokens da URL são válidos
    if (this.areTokensValid(accessToken, portalToken)) {
      return { accessToken, portalToken };
    }

    // Caso os tokens da URL não sejam válidos, tente recuperá-los do armazenamento local
    accessToken = this.storageService.get<string>(PortfolioStorage.AccessToken);
    portalToken = this.storageService.get<string>(PortfolioStorage.AccessToken);

    // Validar se os tokens do armazenamento local são válidos
    if (this.areTokensValid(accessToken, portalToken)) {
      return { accessToken, portalToken };
    }

    return null;
  }

  private getTokensFromRoute(route: ActivatedRouteSnapshot): Tokens | null {
    const accessToken = route.queryParamMap.get('liv-token-pto');
    const portalToken = route.queryParamMap.get('liv-token-ptl');
    return this.areTokensValid(accessToken, portalToken)
      ? { accessToken, portalToken }
      : null;
  }

  private getTokensFromStorage(): Tokens | null {
    const accessToken = this.storageService.get<string>(
      PortfolioStorage.AccessToken
    );
    const portalToken = this.storageService.get<string>(
      PortfolioStorage.PortalToken
    );
    return this.areTokensValid(accessToken, portalToken)
      ? { accessToken, portalToken }
      : null;
  }

  private areTokensValid(
    accessToken: string | null,
    portalToken: string | null
  ): boolean {
    return (
      accessToken !== null &&
      portalToken !== null &&
      typeof accessToken === 'string' &&
      accessToken.trim().length > 0 &&
      typeof portalToken === 'string' &&
      portalToken.trim().length > 0
    );
  }

  private canAccess(tokens: Tokens): Observable<boolean> {
    const { accessToken, portalToken } = tokens;
    const canForward = accessToken !== null && portalToken !== null;
    if (canForward) {
      this.storageService.save<string>(
        PortfolioStorage.AccessToken,
        accessToken
      );
      this.storageService.save<string>(
        PortfolioStorage.PortalToken,
        portalToken
      );
    }
    return of(canForward);
  }

  private authenticateLocalUser() {
    return this.httpClient
      .post<{ data: { token: string } }>(`${environment.apiLiv}/login`, {
        login: LOCAL_USER_MAIL,
        password: LOCAL_USER_PASSWORD
      })
      .pipe(
        switchMap((user) =>
          this.fetchUserData(user.data.token).pipe(
            map(({ data }) => ({ user, data }))
          )
        ),
        map(({ data, user }) => this.handleAuthenticationResponse(data, user)),
        catchError((error: LivPortfolioErrorResponse) =>
          this.handleError(error)
        )
      );
  }

  private handleAuthenticationResponse(
    data: Model<StrapiUserSessionModel>,
    user: { data: { token: string } }
  ) {
    if (!data.attributes) {
      return this.router.createUrlTree(['/auth'], {
        queryParams: { error: ErrorType.AccessDenied }
      });
    }
    this.storageService.save(
      PortfolioStorage.RunningInDevMode as string,
      new Date().toISOString()
    );
    const { jwt } = data.attributes;
    return this.canAccess({ accessToken: jwt, portalToken: user.data.token });
  }

  private fetchUserData(
    token: string
  ): Observable<Data<StrapiUserSessionModel>> {
    const headers = new HttpHeaders({ 'token-api-liv': token });
    return this.httpClient.get<Data<StrapiUserSessionModel>>(
      `${environment.apiPortfolio}/auth/local`,
      { headers }
    );
  }

  private handleError(error: LivPortfolioErrorResponse): Observable<UrlTree> {
    console.error('An error occurred:', error);
    this.storageService.deleteAll();
    this.loadingOverlayService.remove();
    return of(
      this.router.createUrlTree(['/auth'], {
        queryParams: { error: ErrorType.UnexpectedError }
      })
    );
  }

  private validateLocalOrigin(url: string): boolean {
    const parsedUrl = new URL(url);
    const regex = /^(https?:\/\/)?(localhost|127\.0\.0\.1)(:\d+)?/;
    return regex.test(parsedUrl.origin);
  }
}
