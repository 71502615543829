import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonsModule } from './core/commons/commons.module';
import { GlobalErrorHandler } from './core/errors/GlobalErrorHandler';
import { httpInterceptorProviders } from './core/interceptors';
import { decrypt, encrypt } from './core/security/state-cypher';
import { ApiGatewayService } from './core/services/api/api-gateway.service';
import { ApiLivGatewayService } from './core/services/api/api-liv-gateway.service';
import { ConfigService } from './core/services/config.service';
import { GeneralService } from './core/services/general.service';
import { BookState } from './core/store/book/book.state';
import { BookPagesState } from './core/store/book-pages/book-pages.state';
import { KibanaState } from './core/store/kibana/kibana.state';
import { SessionState } from './core/store/session/session.state';
import { TeacherState } from './core/store/teacher/teacher.state';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeBr, 'pt-BR');

export const configFactory = (configService: ConfigService) => {
  return (): Promise<void> => configService.loadConfig();
};

const PERSISTED_STATES = [SessionState, BookState, BookPagesState];
const STATES = [...PERSISTED_STATES, KibanaState, TeacherState];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonsModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false //https://www.ngxs.io/advanced/options (injectContainerState false é recomendado p/ melhor performance)
      }
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      namespace: '@liv-prof-pfo',
      keys: [...PERSISTED_STATES],
      serialize: encrypt,
      deserialize: decrypt
    }),
    NgbModule
  ],
  providers: [
    httpInterceptorProviders,
    ApiGatewayService,
    ApiLivGatewayService,
    GeneralService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideHotToastConfig()
  ]
})
export class AppModule {}
