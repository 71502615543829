import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  RemoveAccessTokenAction,
  SetAccessTokenAction
} from './session.action';

export interface SessionStateModel {
  accessToken: string | null;
}

const SESSION_STATE_TOKEN = new StateToken<SessionStateModel>('session');

@State<SessionStateModel>({
  name: SESSION_STATE_TOKEN,
  defaults: {
    accessToken: null
  }
})
@Injectable()
export class SessionState {
  @Selector() static accessToken({ accessToken }: SessionStateModel): string {
    return accessToken;
  }

  @Action(SetAccessTokenAction) setAccessToken(
    { patchState }: StateContext<SessionStateModel>,
    { accessToken }: SetAccessTokenAction
  ): void {
    patchState({
      accessToken
    });
  }

  @Action(RemoveAccessTokenAction) removeAccessToken({
    patchState
  }: StateContext<SessionStateModel>): void {
    patchState({
      accessToken: null
    });
  }
}
