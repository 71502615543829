import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortfolioStorage } from '../enums/portfolio-storage.enum';
import { StorageService } from '../services/storage.service';

const uris = {
  noNeedsAuthToken: ['assets', 'storage.googleapis'],
  needsPortalAuthToken: ['api.inteligenciadevida']
} as const;

export function requestNeedsPortalAuthToken(url: string): boolean {
  return new RegExp(`${uris.needsPortalAuthToken.join('|')}`, 'i').test(url);
}

export function requestNoNeedsAuthToken(url: string): boolean {
  return new RegExp(`${uris.noNeedsAuthToken.join('|')}`, 'i').test(url);
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (requestNoNeedsAuthToken(request.url)) {
      return next.handle(request);
    }

    let accessToken = this.storageService.get<string>(
      PortfolioStorage.AccessToken
    );

    if (requestNeedsPortalAuthToken(request.url)) {
      accessToken = this.storageService.get<string>(
        PortfolioStorage.PortalToken
      );
    }

    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    );
  }
}
