import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { KibanaService } from '../../services/api/kibana.service';
import {
  KibanaCustomTeacherShareModalOpen,
  KibanaCustomTeacherQRCodeDownload,
  KibanaPortfolioExternalLink,
  KibanaPortfolioModel,
  KibanaPortfolioRecordModel,
  KibanaPortfolioRegisterPagesEdit,
  KibanaCustomTeacherShareMediaDownload,
  KibanaNewCreativeSpace
} from './kibana.models';
import {
  SaveKibanaExternalLinkAction,
  SaveKibanaShareModalOpenAction,
  SaveKibanaGeneralAction,
  SaveKibanaQRCodeDownloadAction,
  SaveKibanaRegisterAction,
  SaveKibanaRegisterPagesEditAction,
  SaveKibanaShareMediaDownloadAction,
  SaveKibanaNewCreativeSpaceAction,
  SaveKibanaClassSelectionAction
} from './kibana.actions';
import { Observable } from 'rxjs';
import { LivPortfolioSuccessResponse } from '../../models/liv-portfolio-response-protocol.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface KibanaStateModel {}

const KIBANA_STATE_TOKEN = new StateToken<KibanaStateModel>('kibana');

@State<KibanaStateModel>({
  name: KIBANA_STATE_TOKEN,
  defaults: {}
})
@Injectable()
export class KibanaState {
  constructor(private kibanaService: KibanaService) {}

  @Action(SaveKibanaGeneralAction)
  saveKibanaGeneral(
    _: StateContext<KibanaPortfolioModel>,
    { payload }: SaveKibanaGeneralAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaGeneral(payload);
  }

  @Action(SaveKibanaRegisterAction)
  saveKibanaRegister(
    _: StateContext<KibanaPortfolioRecordModel>,
    { payload }: SaveKibanaRegisterAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaRegister(payload);
  }

  @Action(SaveKibanaRegisterPagesEditAction)
  saveKibanaRegisterPagesEdit(
    _: StateContext<KibanaPortfolioRegisterPagesEdit>,
    { payload }: SaveKibanaRegisterPagesEditAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaRegisterPagesEdit(payload);
  }

  @Action(SaveKibanaExternalLinkAction)
  saveKibanaExternalLink(
    _: StateContext<KibanaPortfolioExternalLink>,
    { payload }: SaveKibanaExternalLinkAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaExternalLink(payload);
  }

  @Action(SaveKibanaQRCodeDownloadAction)
  saveKibanaQRCodeDownload(
    _: StateContext<KibanaCustomTeacherQRCodeDownload>,
    { payload }: SaveKibanaQRCodeDownloadAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaCustomTeacher({
      trackingId: 'portfolio-qrcode-download',
      objectData: payload
    });
  }

  @Action(SaveKibanaShareMediaDownloadAction)
  saveKibanaShareMedia(
    _: StateContext<KibanaCustomTeacherShareMediaDownload>,
    { payload }: SaveKibanaShareMediaDownloadAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaCustomTeacher({
      trackingId: 'portfolio-share-media-download',
      objectData: payload
    });
  }

  @Action(SaveKibanaShareModalOpenAction)
  saveKibanaShareModalOpen(
    _: StateContext<KibanaCustomTeacherShareModalOpen>,
    { payload }: SaveKibanaShareModalOpenAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaCustomTeacher({
      trackingId: 'portfolio-share-open',
      objectData: payload
    });
  }

  @Action(SaveKibanaNewCreativeSpaceAction)
  saveKibanaNewCreativeSpace(
    _: StateContext<KibanaNewCreativeSpace>,
    { payload }: SaveKibanaNewCreativeSpaceAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaCustomTeacher({
      trackingId: 'portfolio-new-creative-space',
      objectData: payload
    });
  }

  @Action(SaveKibanaClassSelectionAction) saveKibanaClassSelection(
    _: StateContext<KibanaNewCreativeSpace>,
    { payload }: SaveKibanaClassSelectionAction
  ): Observable<LivPortfolioSuccessResponse<void>> {
    return this.kibanaService.saveKibanaCustomTeacher({
      trackingId: 'class-selection',
      objectData: payload
    });
  }
}
