import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeforeLoadGuard } from './core/security/guards/before-load.guard';

const routes: Routes = [
  {
    path: 'livro-do-professor',
    canActivate: [BeforeLoadGuard],
    loadChildren: () =>
      import('./shell/shell.module').then((m) => m.ShellModule)
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('./auth/auth.component').then((m) => m.AuthComponent)
  },
  {
    path: '404',
    loadComponent: () =>
      import('./not-found/not-found.component').then((m) => m.NotFoundComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
